/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Seznam darů"}>
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--30 pl--40 pr--40 pt--30" menu={true}>
            
            <Menu className="--full pb--06 pl--06 pr--06 pt--06" style={{"maxWidth":""}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <Subtitle className="subtitle-box subtitle-box--invert" content={"23—6—2021"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--22 w--400 ls--004 pl--10 pr--10" innerClassName="pl--0 pr--0" href={"/"} target={""} content={"Místo a čas"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--22 w--400 ls--004 pl--10 pr--10" innerClassName="pl--0 pr--0" href={"/svatebni-dary"} target={""} content={"Svatební dary"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--22 w--400 ls--004 pl--10" innerClassName="pl--0 pr--0" href={"/potvrdit-ucast"} target={""} content={"Potvrdit účast"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="pb--50 pt--80" name={"kontakt"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--0 pr--0 flex--center" anim={null} animS={null} style={{"maxWidth":1080}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center w--300 title-box--invert pt--50" content={"Svatební dary"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--10" name={"svatebni-dary-1"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--50 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"Nějaký pořádny kávovar"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Chctěli bychom konečně pořádný kafé..."}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--sbtn1 btn-box--pbtn3 btn-box--cColor1 btn-box--filling2 fs--22 btn-box--invert mt--20" href={"/potvrdit-ucast"} content={"<span style=\"color: rgb(255, 255, 255);\">Zamluvit</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-c583e9 --center bg--bottom --parallax pb--80 pt--60" name={"paticka"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--40 pt--40">
              
              <Title className="title-box" content={"Těšíme se na Vás!"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"23.6.2021"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}